import styled from "styled-components";
import { Link } from "react-router-dom";
import img from "../../images/mfmlogo.png";

export const FooterContainer = styled.div`
  background: var(--clr-primary-1);
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-top:rem; */
  width: 300px;
  @media screen and (max-width: 952px) {
    place-items: center;
  }
 `;

export const FooterContainerWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;

  @media screen and (max-width: 952px) {
    grid-template-columns: auto;

  }
 
`;

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
   color: #fff;
  @media screen and (min-width: 669px) {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
 
  }
  @media screen and (max-width: 652px) {
    width: 80%;
    padding: 0 17px;
    margin: 0 auto;
 
  }
`;

export const FooterSubHeadingdiv = styled.div`
  display: flex;
  
`;

export const FooterSubHeading = styled.p`
  margin-bottom: 20px;
  align-items: flex-start;
  font-size: 1rem;
  width: 100%;
  @media screen and (max-width: 822px) {
    font-size: 0.9rem;
    justify-content: center;
    margin-bottom: 5px;
  }
`;

export const FootersubTextWrapper = styled.p`
  display: flex;
  margin: 0.5rem auto;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  @media screen and (max-width: 952px) {
  }
`;
export const FooterSubText = styled.p`
  font-size: 1rem;
  margin: 0.5rem auto;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 668px) {
    font-size: 0.75rem;

  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: var(--clr-deep-red);
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 8px 20px;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 820px) {
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0px 30px;
  align-items: flex-start;
  margin: 1rem;
  justify-content: space-between;
  max-height: 150px;
  color: #fff;

  @media screen and (max-width: 952px) {
    justify-content: center;
    align-content: center;
    max-height: 130px;
  }
  @media screen and (max-width: 420px) {
    margin: 0 auto;
    width: 80%;
    padding: 10px 0;
  }
`;

export const FooterLinkTitle = styled.h2`
  text-align: left;
  font-size: 1.2rem;
  color: #fff;

  @media screen and (max-width: 952px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 420px) {
    font-size: 1.1rem;
    text-align: center;
  }
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;

  &:hover {
    color: var(--clr-deep-red);
    transition: 0.3s ease-out;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9rem;
  }
`;

export const SocialMedia = styled.section`
  width: 80%;
  display: flex;
  justify-content: center;
  align-item: center;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1300px;
  margin: 10px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media screen and (min-width: 367px) {
    font-size: 1.4rem;
    font-weight: 700;
  }
  @media screen and (max-width: 367px) {
    font-size: 1.1rem;

  }
`;

export const SocialIcon = styled.img.attrs({
  src: `${img}`,
})`
  width: 45px;
  height: 45px;
  margin-right: 0.5rem;

  @media screen and (min-width: 960px) {
    width: 40px;
    height: 40px;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  padding-left: 15px;
  margin-bottom: 16px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 23px;
`;
