import React from 'react';
import GlobalStyle from './globalStyles';
import { Footer, Navbar } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/HomePage/Home';
import AboutUs from './pages/About/AboutUs';
import Connect from './pages/Connect/Connect';
import Donate from './pages/Give/Donate';
import Branches from './pages/Branch/Branches';
import Contact from './pages/Contact/Contact';
import ScrollToTop from './components/ScrollToTop';
import Prayer from './pages/Prayer/Prayer';
import ConferenceCall from './pages/CommandTheMorning/ConferenceCall';


function App() {
  return (
    <Router>
      <GlobalStyle/>
      <ScrollToTop/>
      <Navbar />
      <Routes>
      <Route path='/' exact element={ <Home/> }></Route>
      <Route path='/about-us' exact element={ <AboutUs/> }></Route>
      <Route path='/connect' exact element={ <Connect embedId="SKqjdk6pnUQ"/> }></Route>
      <Route path='/donate' exact element={ <Donate/> }></Route>
      <Route path='/prayer' exact element={ <Prayer/> }></Route>
      <Route path='/Mfm-branches' exact element={ <Branches/> }></Route>
      <Route path='/Contact' exact element={ <Contact/> }></Route>
      <Route path='/ConferenceCall' exact element={ <ConferenceCall/> }></Route>
    </Routes>
    <Footer/>
  
    </Router>
  
  );
}

export default App;
