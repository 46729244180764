import React from "react";
import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./Navbar.elements";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#f6fafb" }}>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/" onClick={closeMobileMenu}>
            <NavIcon />
            MFM WESTFALEN <br />
            GERMANY
          </NavLogo>
          <MobileIcon onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu onClick={handleClick} click={click}>
            <NavItem>
              <NavLinks to="/" onClick={closeMobileMenu}>
                Home
              </NavLinks>
              <NavLinks to="/about-us" onClick={closeMobileMenu}>
                About Us
              </NavLinks>
              <NavLinks to="/contact" onClick={closeMobileMenu}>
                Contact us
              </NavLinks>

              <NavLinks to="/prayer" onClick={closeMobileMenu}>
                Prayer Points
              </NavLinks>
              
              {/* 
              <NavLinks to="/Connect" onClick={closeMobileMenu}>
                Connect
              </NavLinks> */}
              <NavLinks to="/donate" onClick={closeMobileMenu}>
                Give
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
