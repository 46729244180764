import styled, {createGlobalStyle} from "styled-components"

// import banner from "./images/Year_banner.jpeg";
const GlobalStyle = createGlobalStyle`
:root {
  /* dark shades of primary color*/
  --clr-primary-1: #7d156d;
 
  /* lighter shades of primary color */
  --clr-primary-2: #f6fafb;

  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  /* grey used for paragraphs */
  --clr-grey-3: #959595;
  --clr-light-grey-2: #f6fafb;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-deep-red: #f36e57;
  --clr-black: #222;
  --transition: all 0.3s linear ;
  --spacing: 0.1rem;
  --radius: 0.25rem;

  --max-width: 1170px;
  --fixed-width: 620px;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body {
  font-family: 'Lato', sans-serif;
  color: var(--clr-grey-1);
  line-height: 1.2;
  font-size: 1rem;
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
}
a:hover{
color: ${({ primary }) => (primary ? '#c934b3' : '#f36e57')};
}
`;
export const Container =styled.div`
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "var(--clr-primary-1)" : 'var(--clr-deep-red)')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  align:center;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#4B59F7' : '#c934b3')};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
  
`;
export default GlobalStyle;