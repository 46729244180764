import React from 'react'
import "./Prayer.css";
const Prayer = () => {
  return (
<div className='wrapper'>
<h2>MFM DÜSSELDORF DOWNLOADS</h2>
   <div className='title'>
    <p>Hi there! During some MFM services, prayer points are usually handed out. To ensure everyone has a copy of these prayers and other resources no matter your location, we will be adding them here for download, FREE!!!</p>
   </div>

    <h3>Keep the Fire Burning with MFM Downloads</h3>
    <section className="prayerflex">
    <div className='prayersec'>
    <h4> CLICK HERE TO DOWNLOAD THE PROPHETIC OULOOK FOR THE YEAR 2023</h4>
    <a href="https://www.mountainoffire.org/downloads" target="_blank" rel="noopener noreferrer">
  <button> Download</button></a>
  </div>
  <div className='prayersec'>
    <h4> CLICK HERE TO DOWNLOAD POWER MUST CHANGE HANDS PRAYERS</h4>
    <a href="https://www.mountainoffire.org/pmch" target="_blank" rel="noopener noreferrer"> 
  <button> Download</button> </a>
</div>
</section>
</div>
  )
}

export default Prayer