import React from 'react'
import { Pricing } from '../../components'
import InfoSection from '../../components/InfoSection/InfoSection'
import CommanTheMorning from '../../components/InfoSection/CommanTheMorning'
import InfoSectionTwo from '../../components/InfoSection/InfoSectionTwo'
// import Title from '../../components/InfoSection/Title'
import Slider from '../../components/Slider/Slider'
import { homeObjOne,homeObjThree,homeObjFour } from './Data'
import GalleryPage from '../Gallery/GalleryPage'





const Home = () => {
  return (
    <>
  {/* <Title/> */}
  <Slider />
  <InfoSectionTwo {...homeObjOne}/>
  <CommanTheMorning {...homeObjThree}/>
<Pricing/>
<GalleryPage/>
<InfoSection {...homeObjFour}/>
  
    </>
  )
}

export default Home
