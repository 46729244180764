import First from "../assets/MFMY1.jpg";
import Second from "../assets/First.jpeg";
import Third from "../assets/Third.jpg";
import Fourth from "../assets/Fourth.jpeg";
import Fifth from "../assets/Fifth.jpg";
import Sixth from "../assets/Sixth.jpeg";
import Seventh from "../assets/Seventh.jpg";
import Tenth from "../assets/Tenth.jpeg";
import Nineth from "../assets/Nineth.jpeg";
import Eighth from "../assets/Second.jpg";


const Data = [
   {
  //   // title: "First Slide",
  //   // description: "This is the first slider Image of our carousel",
     urls: First,
   },
  // {
  //   // title: "Second Slide",
  //   // description: "This is the second slider Image of our carousel",
  //   urls: ,
  // },
  {
    // title: "Second Slide",
    // description: "This is the second slider Image of our carousel",
    urls: Second,
  },
  {
    // title: "Third Slide",
    // description: "This is the Third slider Image of our carousel",
    urls: Third,
  },
  {
    // title: "Fourth Slide",
    // description: "This is the Fourth slider Image of our carousel",
    urls: Fourth,
  },
  {
    // title: "Fifth Slide",
    // description: "This is the Fifth slider Image of our carousel",
    urls: Fifth,
  },
  {
    // title: "Sixth Slide",
    // description: "This is the Sixth slider Image of our carousel",
    urls: Sixth,
  },
  {
    // title: "Seventh Slide",
    // description: "This is the Seventh slider Image of our carousel",
    urls: Seventh,
  },
  // {
  //   // title: "Seventh Slide",
  //   // description: "This is the Seventh slider Image of our carousel",
  //   urls: Eighth,
  // },
  {
    // title: "Seventh Slide",
    // description: "This is the Seventh slider Image of our carousel",
    urls: Nineth,
  },
  {
    // title: "Seventh Slide",
    // description: "This is the Seventh slider Image of our carousel",
    urls: Tenth,
  },
  {
    // title: "Seventh Slide",
    // description: "This is the Seventh slider Image of our carousel",
    urls: Eighth,
  },
];
export default Data;