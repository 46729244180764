
import styled from "styled-components";
import img from "../../images/contactImage.svg";
const iframe ='https://maps.google.com/maps?q=Mountain%20Of%20Fire%20And%20Miracles%20Ministries,Dusseldorf,Germany%20Vogelsanger%20Weg%2039,%2040470%20D%C3%BCsseldorf&t=&z=13&ie=UTF8&iwloc=&output=embed'

export const ContactSec = styled.div`
  color: var(--clr-deep-red);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  /* border: 2px solid green; */
  @media screen and (min-width: 768px) {
    justify-content: left;
  }
`;
export const ContactForm = styled.div`
 display: flex;
 flex-direction:column;
  min-width: 320px;
  margin:0 auto;
  border-radius: 5px;

  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction:row;
    width:90%;
    max-width: 700px;
   
  }
`;

export const FormContainer = styled.div`
display: flex;
flex-direction:column;
  @media screen and (min-width: 768px) {
    flex-direction:row;
    justify-contect:space-between;
  }
`;


export const ContactInput = styled.input`
padding: 0.5rem;
margin: 0.5rem;
border: 2px solid var(--clr-primary-1);
border-radius: 5px;
::placeholder,
  ::-webkit-input-placeholder {
    color: var(--clr-primary-1);
  }
  :-ms-input-placeholder {
     color: var(--clr-primary-1);
  };
@media screen and (min-width: 768px) {
  width: 47%;
 height: 20%;
 margin: 0.5rem auto;

  }
`;
export const ContactTextarea = styled.textarea`
padding: 0.5rem;
margin: 0.5rem;
height: 100px;
border-radius: 5px;
border: 2px solid var(--clr-primary-1);
::placeholder,
  ::-webkit-input-placeholder {
    color: var(--clr-primary-1);
  }
  :-ms-input-placeholder {
     color: var(--clr-primary-1);
  };
@media screen and (min-width: 768px) {
  width: 100%;
  height: 150px;
  }
`;

export const Iframe = styled.iframe.attrs({
  src: `${iframe}`,
})`
  max-width: 100%;
  height: 400px;
  width: 100%;
  
  @media screen and (min-width: 1400px) {
    
  }
  `;
  export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${({ primary }) => (primary ? "var(--clr-deep-red)" : 'var(--clr-primary-1)')};
  color: ${props => props.primary ? "white" : "palevioletred"};
  width: 95%;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  margin:0.5rem auto;
  padding: 0.25em 1em;
  float: right;
  border: none;
  border-radius: 3px;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#c934b3' : '#4B59F7')};
  }
  @media screen and (min-width: 768px) {
  width: 98%;
  margin: 0 auto;
  
  }
`;

export const ContactImage = styled.img.attrs({
  src: `${img}`,
})`
margin-top: 0.5rem;
  max-width: 350px;
  height: 250px;
  margin-right: 0.5rem;

  @media screen and (min-width: 960px) {
    min-width: 500px;
    height: 400px
    
  }
`;