import { Carousel } from "react-carousel-minimal";
import First from "../Gallery/Gallery_images/HappyNewYear.jpeg";
import New_month from "../Gallery/Gallery_images/mar_img.jpeg";
import Second from "../Gallery/Gallery_images/propheticyear.jpeg";
import Third from "../Gallery/Gallery_images/prayerImage1.jpeg";
import Fourth from "../Gallery/Gallery_images/prayerImage2.jpeg";
import Fifth from "../Gallery/Gallery_images/prayerImage3.jpeg";
import Sixth from "../Gallery/Gallery_images/prayerImage4.jpeg";
import Seventh from "../Gallery/Gallery_images/prayerImage5.jpeg";
import Eighth from "../Gallery/Gallery_images/Yearofglorious.jpeg";
import Ninth from "../Gallery/Gallery_images/img_design1.jpg";
import Tenth from "../Gallery/Gallery_images/img_design2.jpg";
import Eleventh from "../Gallery/Gallery_images/img_design3.jpg";
import Twelve from "../Gallery/Gallery_images/img_design4.jpg";
import Thirteen from "../Gallery/Gallery_images/img_design5.jpg";
import fourteen from "../Gallery/Gallery_images/img_design6.jpg";
// import fifteen from "../Gallery/Gallery_images/img_design7.jpg";
import "./GalleryPage.css";

function GalleryPage() {
  const data = [
    {image: Ninth,},
    {image: Tenth,},
    {image: Eleventh,},
    {image: Twelve,},
    {image: Thirteen,},
    {image: fourteen,},
    {image: First, },
    {image: New_month, },{image: Second, },
    { image: Third,}, { image: Fourth,},{image: Fifth,},
    {image: Sixth, },
    {image: Seventh,},
    {image: Eighth,},
    // {image: fifteen,},

   
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  
  return (
    <>
      <div className="gallery_wrapper" style={{ textAlign: "center" }}>
           <h2 className="gallery_h2">MFM WESTFALEN BILLBOARD</h2>
          <Carousel
            className="carousel"
            data={data}
            time={2000}
            width="750px"
            height="450px"
            captionStyle={captionStyle}
            radius="10px"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="#f36e57"
            slideImageFit="contain"
            thumbnails={true}
            showNavBtn={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "600px",
              maxHeight: "800px",
              margin: "10px auto",
            }}
          />
       
      </div>
    </>
  );
}

export default GalleryPage;
