import React from 'react';
import { Button } from '../../globalStyles';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
} from 'react-icons/fa';
import {
MdLocationOn,
MdCall
} from 'react-icons/md';

import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterSubHeadingdiv,
  FooterContainerWrapper,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
FootersubTextWrapper,
} from './Footer.elements';

function Footer() {
  return (
    <FooterContainer>
      <FooterContainerWrapper>
        <FooterSubscription>
          <SocialLogo to="/">
            <SocialIcon />
            MFM Westfalen
          </SocialLogo>
          <FooterSubHeading>
            <FooterSubHeadingdiv>
              <MdLocationOn />
              <div style={{ paddingLeft: "10px" }}>
                <b> Address: </b> I. Fließstraße 6a,45356 Essen, Germany.
              </div>
            </FooterSubHeadingdiv>
          </FooterSubHeading>
          <FooterSubHeading>
            <FooterSubHeadingdiv>
              <MdCall />

              <div style={{ paddingLeft: "10px" }}>
                <b>Telephone:</b> <span> +49 1521 2081826, </span> <br />{" "}
                <span style={{ paddingLeft: "75px" }}>+49 176 61105108</span>
              </div>
            </FooterSubHeadingdiv>
          </FooterSubHeading>
        </FooterSubscription>
        <FootersubTextWrapper>
          <FooterSubText>
            Subscribe to stay up to date – MFM Westfalen
          </FooterSubText>
          <Form>
            <FormInput name="email" type="email" placeholder="Your Email" />
            <Button fontBig>Subscribe</Button>
          </Form>
        </FootersubTextWrapper>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkTitle>Useful Links</FooterLinkTitle>
            <FooterLinkItems>
              <FooterLink to="/about-us">&gt; About Us</FooterLink>
              <FooterLink to="/contact">&gt; Contact Us</FooterLink>
              <FooterLink to="/donate">&gt; Donate</FooterLink>
              <FooterLink to="/connect">&gt; Livestream</FooterLink>
              <FooterLink to="/prayer">&gt; Prayer Points</FooterLink>
              <FooterLink to="/">&gt; Testimonies</FooterLink>
              <FooterLink to="/">&gt; Gallery</FooterLink>
              {/* <FooterLink to='/'>&gt; Gallery</FooterLink>
           <FooterLink to='/'>&gt; Gallery</FooterLink> */}
              <FooterLink to="/">&gt; Prayer Request</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterContainerWrapper>

      <SocialMedia>
        <SocialMediaWrap>
          <WebsiteRights>Mfm Westfalen Germany © 2021</WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href={"https://www.facebook.com/mfmdusseldorfgermany"}
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href={"https://www.instagram.com/mfm_dusseldorf_germany/"}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href={
                "https://www.youtube.com/channel/UCgE8yDGVJvNEdRuxxHohPsQ?view_as=subscriber"
              }
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink
              href="/"
              target="_blank"
              aria-label="LinkedIn"
            ></SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
