import styled from "styled-components";
import { Container } from "../../globalStyles";
import { Link } from "react-router-dom";
import img from "../../images/mfmlogo.png";



export const Nav = styled.nav`
  background: var(--clr-primary-1);
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;

  ${Container}
`;

export const NavLogo = styled(Link)`
  color: var(--clr-primary-2);
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-weight: 700;
  
  @media screen and (max-width: 960px) {
    line-height: 1.1;
    font-size: 1.2rem;
  }
`;

export const NavIcon = styled.img.attrs({
  src: `${img}`,
})`
  width: 55px;
  height: 55px;
  margin-right: 0.5rem;

  @media screen and (min-width: 960px) {
    width: 60px;
    height: 60px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: var(--clr-deep-red);
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;
  border-bottom: 2px solid transparent;

  @media screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  
  }
`;

export const NavLinks = styled(Link)`
  color: var(--clr-primary-2);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  &:hover {
    padding-top: 1rem ;
    border-bottom: 2px solid var(--clr-deep-red);
  }


  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover {
      color: var(--clr-primary-1);
      transition: all 0.1s ease;
      border: none;
    }
   
  }
`;
