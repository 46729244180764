import React from "react";
import {
  Image,
  GiveSec,
  Givewrapper,
  Givetitle,
  GiveSecTitle,
  Redborder,
  GiveContainer,
} from "./Donate.element";
import img from "../../images/giving.svg";

const Donate = () => {
  return (
    <GiveContainer>
      <Givetitle>
        We appreciate your donation towards the advancement of the Gospel.
      </Givetitle>
      <Givewrapper>
        <div>
          <Image alt={""} src={img} />
        </div>
        <GiveSec>
          <div>
            <GiveSecTitle>Account details:</GiveSecTitle>
            <p><strong>Account Name: </strong> MFM Dusseldorf Branch </p>
            <p><strong>Bank name:</strong>  Post Bank</p>
            <p><strong>Account No.:</strong> DE 64 3701 0050 0990 836507</p>
            <p><strong>BIC:</strong> PBNKDEFFXXX</p>

            <div style={{ display: "flex", alignContent: "center" }}>
              <Redborder></Redborder>
              <h4>Online Giving Coming Soon</h4>
            </div>
          </div>
        </GiveSec>
      </Givewrapper>
    </GiveContainer>
  );
};

export default Donate;
