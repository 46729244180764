import React from 'react'
import ReactLoading from "react-loading";
import styled from "styled-components";



 const LoadingWrapper  = styled.div`
width: 100vw;
height: 400px;
 padding:2rem 3rem;
 display: grid;
   place-items: center;
`;

export const Loading = () => {
  return (
    <LoadingWrapper>
    <h1>Loading!!!</h1>
     <ReactLoading
            type={"spinningBubbles"}
            color={"hsl(209, 61%, 16%)"}
            height={"5%"}
            width={"5%"}
            timeout={2000}
          />
    </LoadingWrapper>
  )
}
