import React, { useRef } from "react";
import { useState, useEffect } from "react";
import {
  ContactSec,
  Iframe,
  ContactForm,
  ContactInput,
  ContactTextarea,
  Button,
  ContactImage,
  FormContainer
} from "./Contact.elements";
import emailjs from "emailjs-com";
import { Loading } from "../Loading/Loading";
import swal from "sweetalert2";
import { Container } from "../../globalStyles";


 


const Contact = () => {
  const form = useRef();
  const [isloading, setIsLoading] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d6cv1nj",
        "template_so1ptwq",
        form.current,
        "0NRlvxLVbwJbVrgrk"
      )
      .then(() => {
        new swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 6000,
          icon: "success",
          title: "God bless you for contacting us",
          text: "We will get back to you shortly",
          showCloseButton: true,
        });
      })
      .catch((error) => {
        new swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 8000,
          icon: "error",
          title: "Message not sent",
          text: error.message,
          showCloseButton: true,
        });
        alert();
      });

    e.target.reset();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });

  return (
    <>
      {isloading === true ? <Loading /> : <Iframe />}
      <Container>
        <ContactSec>
      <FormContainer>

    
            <ContactImage/>
          <contactImage/>
          <form ref={form} onSubmit={handleSubmit}>
            <h1 style={{textAlign:"center"}}>Send us a Message </h1>
            <ContactForm>
              <ContactInput placeholder="First Name" name="firstname"  required/>
              <ContactInput placeholder="Last Name" name="lastname" required/>
              <ContactInput placeholder="Email" name="email" required/>
              <ContactInput placeholder="Phone Number" name="phonenumber" required/>
              <ContactTextarea 
                placeholder="Message"
                name="message"required
              ></ContactTextarea>
        
            <Button 
                primary fontBig      
              type="submit"
            >
              Submit
            </Button>
            </ContactForm>
          </form>
            </FormContainer>
        </ContactSec>
      </Container>
    </>
  );
};

export default Contact;
