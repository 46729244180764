import React from "react";
import { Container } from "../../globalStyles";
import img from "../../images/prayer.jpeg";
import img2 from "../../images/conferencecall.png";
import "./ConferenceCall.css";
const ConferenceCall = () => {
  return (
    <Container>
      <br />
      <div className="call_wrapper">
        <div className="ImgWrapper">
          <img src={img} alt="payer_img" />
        </div>
        <div className="text_wrapper">
         
          <h2 style={{textAlign:"center"}}>
            Join our prayer line as we “Command the Morning” every Monday
            morning.
          </h2>
          <p>
            {" "}
            <b>Time:</b> 6:00AM – 6:30AM
          </p>
          <p>
            {" "}
            <b> Venue:</b> Telephone conference by dialing 0221 98203433 and
            entering the code 327137.
          </p>
          <p>
            {" "}
            Please <b>mute</b> your phone when you join.
          </p>
          <p>
            {" "}
            “Hast thou commanded the morning since thy days; and caused the
            dayspring to know his place – Job 38:12”
          </p>

          <p>God bless you as you join.</p>
        
          <div className="connect_container">
             <div>
              <img src={img2} alt="payer_img" width={50} height={50} /> 

              </div>
              <div className="link_container">
              <a
             
                href="https://play.google.com/store/apps/details?id=com.freeconferencecall.sipphone.fcchd&hl=en&gl=US"
                target="_blank"
                rel="nofollow noreferrer"
              > 
              Download App.
              </a>
              <br />
              <a
                href="https://drive.google.com/file/d/1i4lanK9cBiIxXQ150tHV1CQ1tvP-atRu/view?usp=sharing"
                target="_blank"
                rel="nofollow noreferrer"
              >
              To connect via internet
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ConferenceCall;
