
export const homeObjOne = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Worship with us today at',
  headline: 'Welcome to Mountain of Fire and Miracles Ministries, Westfalen, Germany',
  description:
    'Surely the Lord is in this place...(Genesis 28:16)',
  buttonLabel: 'Connect with us Today',
  imgStart: '',
  img: require('../../images/bg_image.jpg').default,
  alt: 'Credit Card',
  start: ''
};



export const homeObjThree = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Prayer",
  headline: "Command The Morning",
  description: `"Hast thou commanded the morning since thy days; and caused the dayspring to know his place - Job 38:12". Join us as we "Command the Morning" on Monday mornings between [...]`,
  buttonLabel: "Read More",
  imgStart: "start",
  img: require("../../images/img_design4.jpg").default,
  alt: "Vault",
  start: "true",
};


export const homeObjFour = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Live Broadcast',
  headline: 'Watch live broadcast of Sunday Family Worship service',
  description:
    `Watch live broadcast of Sunday Family Worship service from 10AM (Eastern Time) on our YouTube Page`,
  buttonLabel: 'Connect Now',
  imgStart: '',
  img: require('../../images/svg-3.jpeg').default,
  alt: 'Vault',
  start: 'true'
};

