import React from 'react';
import { Button } from '../../globalStyles';

import { MdGroupAdd } from 'react-icons/md';
import { ImBullhorn } from 'react-icons/im';
import { IoIosPeople } from 'react-icons/io';

import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,

  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';

function Pricing() {
  return (
    <IconContext.Provider value={{ color: 'var(--clr-primary-1)', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingContainer>
            <PricingCard to='/about-us'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <IoIosPeople />
                </PricingCardIcon>
                <PricingCardPlan>About Us</PricingCardPlan>
                <PricingCardFeatures>
               
                  <PricingCardFeature>MFM Ministries is a full gospel ministry devoted to the Revival of Apostolic Signs and unlimited demonstration.....</PricingCardFeature>
                 
                </PricingCardFeatures>
                <Button primary>Read More...</Button>
              </PricingCardInfo>
            </PricingCard>

            <PricingCard to='/about-us'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <ImBullhorn />
                </PricingCardIcon>
                <PricingCardPlan>Vision</PricingCardPlan>
              
               
                <PricingCardFeatures>
                 <PricingCardFeature>To propagate the gospel of our Lord Jesus Christ all over the world and bring together children of the Most High God...</PricingCardFeature>
                 
                </PricingCardFeatures>
                <Button primary>Read More...</Button>
              </PricingCardInfo>
            </PricingCard>


            <PricingCard to='/prayer'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <MdGroupAdd />
                </PricingCardIcon>
                <PricingCardPlan>Prayer Point</PricingCardPlan>
              
                <PricingCardFeatures>
              <PricingCardFeature>MFM MINISTRIES IS A FULL GOSPEL MINISTRY DEVOTED TO THE REVIVAL OF APOSTOLIC SIGNS....</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Join Now</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Pricing;
