import styled from "styled-components";
const img= require('../../images/Pastor-Olukoya-MFM.png').default;


export const AboutSec = styled.div`
  color: var(--clr-grey-1);
  margin: 2rem 0 ;
  text-align:justify;
  line-height:2;
  @media screen and (min-width: 768px) {
    font-size:1.3rem;
  }
`;
export const Abtcontainer = styled.div`
  display:flex;
  flex-direction: column;
  @media screen and (min-width: 968px) {
    flex-direction: row;
    justify-contents: space-around;
  }
`;
export const AbtWapper = styled.div`

  @media screen and (min-width: 768px) {
    margin-right: 3rem;
    margin-bottom:1.2rem;
  }
`;
export const ImgWapper = styled.div`
 text-align:center;
 margin-bottom:1.2rem;
 
 @media screen and (min-width: 968px) {
   text-align:center;
    margin-top:6rem;
   
  }
`;
export const Title = styled.h3`
  font-size: 1.3em;
  text-align: center;
  color: var(--clr-deep-red);
  margin-bottom:1.3rem;
  @media screen and (min-width: 768px) {
    font-size: 1.5em;
    margin-bottom:2rem;
   
  }
`;

export const Image = styled.img.attrs({
  src: `${img}`,
})`
  max-width:330px;
  height: 260px;
  margin-top: 1rem;
  border-radius:5px;
  

  @media screen and (min-width: 1400px) {
    width: 300px;
    height: 250px;
  }
`;
export const TabWrapper = styled.div`
;
margin-top:2rem;
font-weight:700;
 @media screen and (min-width: 768px) {
  
  }
`;