import styled from 'styled-components';

export const InfoSec = styled.div`
  color: #fff;
  padding: 90px 0;
  background: ${({ lightBg }) => (lightBg ? '#fff' : 'var(--clr-deep-red)')};

  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  
  @media screen and (max-width: 668px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 600px;
  padding-top: 0;
  @media screen and (max-width: 768px) {
    padding-bottom: 5px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 80vw;
  width: 90%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width:90vw;
  }
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? 'var( --clr-light-grey-2)' : '#1c2237')};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  /* border: 2px solid blue; */
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  border-radius: 0.6rem;
  @media screen and (max-width: 768px) {
    max-width: 250px;
    max-height: 420px;
    margin: 0 auto;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? 'var( --clr-light-grey-2)' : '#1c2237')};
  @media screen and (max-width: 768px) {
    font-size: 0.7em;
    margin-bottom: 5px;
  }
`;