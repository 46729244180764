import styled from 'styled-components';

export const GiveContainer = styled.div`
  width: 90vw;
  margin: 0 auto;
  display: grid;
  justify-content: center;
`;

export const Givetitle = styled.div`
  display:flex;
  width: 80vw;
  font-size: 1.2rem;
  font-weight:600;
  text-align:center;
  align-items:center;
 justify-content:center;
 
 margin:1rem 2rem;
  @media screen and (min-width: 868px) {
    font-size: 2rem;
  }
`;

export const Givewrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;

  
  @media screen and (min-width: 868px) {
    flex-direction: row;
    justify-content:space-between;
    width: 90vw;
  }
`;
export const Image = styled.img`
  border: 0;
  margin: 0 auto;
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
  width: 70vw;
  display: flex;
  min-height: 350px;
  align-item:center;
  @media screen and (min-width: 868px) {
  width: 40vw;
  
  }
  @media screen and (max-width: 868px) {
  display: none;
  }
`;

export const GiveSec = styled.div`
display: flex;
flex-direction:column;
color: var(--clr-grey-1);
  margin: 0 auto ;
  padding: 1.5rem;
  line-height:2;
  width: 70vw;
  @media screen and (min-width: 868px) {
    font-size:1.2rem;
    padding: 1.5rem 1rem;
    justify-content:center;
    align-items:center;
  }
`;


export const GiveSecTitle = styled.div`
font-weight:700;
font-size: 1.2rem;  
  }
  @media screen and (min-width: 868px) {
    font-size:1.4rem;
    
  }
  @media screen and (min-width: 1208px) {
    font-size:2rem;
  }
`;
export const Redborder = styled.div`
border-left:7px solid red;
height: 30px;
padding: 0.4rem;

@media screen and (min-width: 1208px) {

height: 50px;
padding: 0.4rem;
  }
  
`;
