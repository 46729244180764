import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui//lab/TabList';
import { Image,AboutSec,Abtcontainer,AbtWapper,ImgWapper,Title,TabWrapper} from "./About.elements";
import TabPanel from '@material-ui//lab/TabPanel';
import { Container } from "../../globalStyles";

export default function AboutUs() {
  const [value, setValue] = React.useState('1');
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    
    <Container>
    <Box sx={{ typography: 'body1'}}>
    <AboutSec>
    <Abtcontainer>
  <AbtWapper>
  <Title>About MFM</Title> 
  <p> 
MFM Ministries is a full gospel ministry devoted to the Revival of Apostolic Signs, Holy Ghost fireworks and the unlimited demonstration of the power of God to deliver to the uttermost.
<br/>
Absolute holiness within and without as the greatest spiritual insecticide and a pre-requisite for Heaven is taught openly. MFM is a do-it-yourself Gospel ministry where your hands are trained to wage war and your fingers to do battle.
<br/>
At MFM, aggressive prayer is considered as an aid to spiritual focus and a check against being overwhelmed by the flesh.
</p>
</AbtWapper>

<ImgWapper>
<Image/>
<h6 > Dr. D.K Olukoya.
<br/>
General Overseer MFM Worldwide</h6>
</ImgWapper>
</Abtcontainer>

<div>
    <Title> DR. DANIEL AND SIS. SHADE OLUKOYA</Title>
    <p>
    
Dr. Daniel Kolawole Olukoya (DKO) is the General Overseer of Mountain of Fire and Miracles Ministries Worldwide. Soon after completing his Ph.D. in the United Kingdom, and while working at the Nigerian Institute of Medical Research, Dr. Daniel Kolawole Olukoya (DKO) summoned a prayer meeting. This was on a Wednesday in 1989. 24 brethren showed up at the meeting. The venue of that meeting was in the living room of DKO.
<br/>
After that meeting, the voice of providence kept calling for subsequent meetings. At these meetings, the power of God came down and verifiable miracles began to happen in the lives of the attending brethren. The power of God began to evangelise the prayer meetings and a spiritual explosion began with people coming from far and wide to seek the face of God.
<br/>
With more and more people attending the prayer meetings, a larger location was required. The prayer meetings moved to 60 Old Yaba Road, Yaba, Lagos. Here, the teeming crowd of believers grew in even more amazing proportion necessitating yet another relocation.
<br/>
The prayer group was offered and purchased an abandoned slum located at 13 Olasimbo Street, Onike, Yaba. It was a fairly large piece of land near the second gate of the University of Lagos. That slum has now been transformed into the International Headquarters of the Mountain of Fire and Miracles Ministries of which Dr. Olukoya is the General Overseer. The name "Mountain of Fire & Miracles Ministries" was revealed to DKO during normal prayer and the church had its first service on April 24, 1994.
<br/>
Even at the new location, believers and those seeking help kept coming leading to a very large congregation. This situation posed a challenge of crowd, growth and expansion management to the leadership of the church. Thus, under the direction of the Lord, MFM evolved a strategy of a network of branches in every state capital, local government headquarters, senatorial district and locality. Till this day and across the world, MFM branches spring up as one of the fastest growing churches of this generation. International church branches first started in London before spreading to the United States, Canada, the rest of Europe, the Caribbean, Africa and Asia. On every continent, MFM is profoundly visible and greatly impactful.
    </p>
  </div>
  

 
      <TabContext value={value}>
      <TabWrapper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab style={{fontWeight:"700",color:"var(--clr-deep-red)",fontSize:"1em"}} label="What we Believe" value="1" />
            <Tab style={{fontWeight:"700",color:"var(--clr-deep-red)",fontSize:"1em"}} label="Mission & Vision" value="2" />
          </TabList>
        </Box>
        </TabWrapper>


        <TabPanel value="2"> 
      <ul>
      <li>To propagate the gospel of our Lord Jesus Christ all over the world</li>
      <li>To promote the revival of Apostolic signs, wonders and miracles</li>
      <li>To bring together children of God who are lost in dead churches</li>
      <li>To train believers in the art and science of spiritual warfare; thus making them an aggressive and victorious army for the Lord</li>
      <li>To train believers to receive Holy Ghost baptism and fire as well as a daily walk and relationship with the Holy Spirit</li>
      <li>To train believers to receive Holy Ghost baptism and fire as well as a daily walk and relationship with the Holy Spirit</li>
      <li>To turn the joy of our enemies to sorrow. That is why we would always have a Deliverance ministry wherever we are. If you do not believe in deliverance, you are not supposed to be in MFM</li>
      <li>To build an aggressive end-time army for the Lord. MFM is an end-time church where we build an aggressive end-time army for the Lord</li>
      <li>What is an end-time church? An end-time church is a church where a sinner enters with two options: he either repents or does not come back, contrary to the present day church where sinners are comfortable and find things so easy and convenient</li>
      <li>To deliver those who have become slaves to pastors, prophets and apostles</li>
      <li>To build up heavenly-bound and aggressive Christians. The priority in MFM is for people to make heaven. It is not a worldly Church</li>
      <li>To build up prayer eagles</li>
      <li>To purify the Pentecostal dirtiness of this age</li>
      </ul>
        </TabPanel>
        
        <TabPanel value="1"> 
        <ul>
<li>The Scriptures are the inspired Word of God; the only basis for our faith and fellowship</li>
<li>The One True God, ETERNAL EXISTENT in three Persons. God The Father, God The Son and The Holy Spirit</li>
<li>The fall and deprivation of mankind, necessitating redemption through the Blood of Jesus Christ</li>
<li>The Salvation of Mankind is through the Redeeming Work of Jesus Christ and the Regenerative Work of the Holy Spirit. Sanctification is seen as an act of separation from that which is evil</li>
<li>The Baptism of the Holy Spirit – Acts 2:4; 10:44 and 19:1-6</li>
<li>Restitution for past wrongs where possible</li>
<li>The Ordinance of the Church, the Lord’s Supper and Water Baptism</li>
<li>The Church Universal, both visible and invisible</li>
<li>The Ministry, divinely called and scripturally ordained as that approved of Almighty God</li>
<li>Divine healing as provided by the Lord Jesus Christ</li>
<li>The Rapture ushering all believers into the Marriage Supper of the Lamb, and the second advent of the Lord Jesus Christ when He will physically land on the earth</li>
<li>The Millennial Reign of Christ</li>
<li>The Final Judgement</li>
<li>The New Heaven and Earth</li>
</ul>
        </TabPanel>
      
      </TabContext>
    </AboutSec>
    </Box>
    </Container>
  );
}


